/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ag-grid */
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

// Material
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

 .staffing-modal {
  --width: auto;
  --height: auto;
  --max-height: 90%;
  --min-height: 23%;
  --max-width: 75%;
  --min-width: 40%;
 
  > .ion-page {
    border-radius: 10px;
    position: absolute;
    display: block;
    text-align: center;
    font-size: clamp(16px, 4vw, 22px);
  }
}

.duplicate-staffing-modal {
  --width: auto;
  --height: auto;
  --max-height: 90%;
  --min-height: 25%;
  --max-width: 75%;
  --min-width: 40%;
 
  > .ion-page {
    border-radius: 10px;
    position: absolute;
    display: block;
    text-align: center;
   }
}

.error-staffing-modal {
  --width: auto;
  --height: auto;
  --max-height: 90%;
  --min-height: 23%;
  --max-width: 75%;
  --min-width: 40%;
 
  > .ion-page {
    border-radius: 10px;
    position: absolute;
    display: block;
    text-align: center;
   }
}

.msg-staffing-modal {
  --width: auto;
  --height: auto;
  --max-height: 90%;
  --min-height: 20%;
  --max-width: 75%;
  --min-width: 40%;
 
  > .ion-page {
    border-radius: 10px;
    position: absolute;
    display: block;
    text-align: center;
   }
}

.numericInput{
  background-color: aliceblue;
}

.deltaCra{
  //border-radius: 10px;
  vertical-align: middle;
}

// .deltaAbs{
//   margin-left: 20px;
// }

.ag-theme-alpine .ag-row-odd {
  background-color: #fcfcfc;
  background-color: var(--ag-odd-row-background-color, #f0eded);
}

.alert-wrapper {
  width: 700px !important;
  max-width: 2000px !important;
}

.alert-button-group {
  display: inline;
}

.cookie-consent {
  button.alert-button:nth-child(1){
    color: crimson;
  }

  button.alert-button:nth-child(2){
    color: gray;
  }
}

.pointing-cursor{
  cursor: pointer;
}

.comment {
  line-height: normal !important;
}

@media (prefers-color-scheme: dark) {
  #mat-form-field-label-1{
    color: #fcfcfc;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline{
    background: #fcfcfc;
  }

  .mat-button-wrapper{
    color: #fcfcfc;
  }
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
  white-space: normal !important;
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.infoText {
  padding-right: 4%;
  font-size: 0.8rem;
  color: #8c8c8c;
  font-style: italic;
}

.ag-cell-wrap-text {
  word-break: break-word;
}
